body{
    font-family: 'Poppins', sans-serif;
    color: #212529;
    font-weight: 400;
    font-size:15px;
}
a {
    color: #6D23FF;
}
input:focus{
    box-shadow: none !important;
}
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }
    /* Firefox  */
    input[type=number] {
    -moz-appearance: textfield;
}
/* Sidebar */
.c-sidebar .c-sidebar-brand {
    background: #ffffff !important;
}
.c-sidebar{
    background: #ffffff !important;
}
.c-header {
    background: #6D23FF !important;
    border-bottom: 1px solid #d8dbe0;
}
.c-header .c-header-nav .c-header-nav-link, .c-header .c-header-nav .c-header-nav-btn {
    color: #ffffff !important;
}
.c-sidebar .c-sidebar-nav-dropdown-toggle:hover, .c-sidebar .c-sidebar-nav-link:hover {
    color: #6D23FF!important;
    background: #f2f2f4;
}
/* .c-sidebar-brand>img {
    width: 65px;
} */
.c-sidebar-nav li>a {
    color: #7e7e7e!important;
    background: transparent;
    /* border-bottom: 1px dotted #4d4d4d; */
    /* box-shadow: 0px 3px 10px -4px #080808; */
    font-size: 15px;
}
.c-sidebar-nav-dropdown-items li>a {
    box-shadow: none !important;
    border: none !important;
}
.c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-toggle, .c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link {
    color: #6D23FF;
}

.c-sidebar .c-active.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon, .c-sidebar .c-sidebar-nav-link.c-active .c-sidebar-nav-icon {
    color: #6D23FF;
}
/* .c-header {
min-height: 66px;
} */
/* .c-avatar-img {
width: 50px;
height: 50PX;
} */
.c-sidebar .c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon, .c-sidebar .c-sidebar-nav-link:hover .c-sidebar-nav-icon {
    color: #6D23FF;
}
.c-sidebar.c-sidebar-minimized .c-sidebar-nav-item:hover > .c-sidebar-nav-link, .c-sidebar.c-sidebar-minimized .c-sidebar-nav-item:hover > .c-sidebar-nav-dropdown-toggle {
    background: #2b2b2b;
}
.c-sidebar .c-sidebar-nav-link .c-sidebar-nav-icon, .c-sidebar .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
    color: #787f91;
}
.c-sidebar.c-sidebar-minimized .c-sidebar-nav-item:hover > .c-sidebar-nav-link .c-sidebar-nav-icon, .c-sidebar.c-sidebar-minimized .c-sidebar-nav-item:hover > .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
    color: #6D23FF;
}
.c-sidebar.c-sidebar-minimized .c-sidebar-nav > .c-sidebar-nav-dropdown:hover {
    background: #2b2b2b;
}
.c-sidebar.c-sidebar-minimized .c-sidebar-nav > .c-sidebar-nav-dropdown > .c-sidebar-nav-dropdown-items {
    background: #2b2b2b;
}
.c-sidebar .c-active.c-sidebar-nav-dropdown-toggle, .c-sidebar .c-sidebar-nav-link.c-active {
    color: #6D23FF !important;
    background: #f2f2f4;
}
.manage-permission{
    box-shadow: 0px 0px 10px -4px #989898;
    border: none;
    border-color: transparent;
}
/* .c-sidebar-minimized ul li a:hover > span {
    display: inline !important;
}
.c-sidebar-minimized ul li a > span {
    display: none;
}
.c-sidebar-minimized ul li a {
    padding: 15px 15px 15px 25px;
} */
/* .c-sidebar-minimized .c-sidebar-brand > img {
    width: 52px;
} */

.relative {
    position: relative;
}
/* Login */
.space-bw {
    justify-content: space-between
}
.custom-control-input:checked ~ .custom-control-label::before {
    border-color: #6D23FF !important;
    background-color : #6D23FF !important;
}
label.custom-control-label:focus {
    outline: none !important;
}
.page-link{
    color:#6D23FF;
}
.page-link:hover{
    color:#6D23FF;
}
.page-item.active .page-link {
    color: #fff;
    background-color:#6D23FF;
    border-color: #6D23FF;
}
.justify-content-start {
    justify-content: flex-end !important;
}

 /* React select */
.css-g1d714-ValueContainer{
    width: 300px !important;
}
.css-1hwfws3{
    width: 300px !important;
}

/* Toast */
/* .Toastify__toast--success {
    background: #18DAE6 !important;
} */

/* Table */
.CDataTable_arrow-position__2xCKr {
    color: #ffffff !important;
}
.CDataTable_transparent__3viwN {
    opacity: 1 !important;
}
.table th, .table thead th {
    font-weight: 500;
    text-transform: uppercase;
    font-size: 12px;
    background: #2b2b2b;
    color: #fff;
    padding: 6px;
    overflow: visible !important;
}
.table-badge{
    height: 35px;
    width: 40px;
    display: inline-flex !important; 
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    color: #ffffff;
    cursor: pointer;
}
.table-badge:last-child{
    margin-right: 0px;
}

/* button */
.btn-primary {
    background-color: #6D23FF;
    border-color: #6D23FF;
}
.pull-right {
    float: right;
}
button:hover, button:focus, button:active, a:hover, a:focus, a:active{
    box-shadow: none !important;
    outline: none;
}
a:hover, a:focus, a:active{
    /* color: #f95c2e; */
}

/* Badge */
.badge-primary:hover, .btn-primary:hover, .btn-primary:active, .btn-primary:focus{
    background-color: #219198 !important;
    border: 1px solid #219198 !important;
}
.badge-warning:hover{
    background-color: #dea701;
}
.badge-warning {
    color: #4f5d73;
    background-color: #fec107;
}
.badge-danger:hover{
    background-color: #de4a58;
}
.badge-danger {
    color: #fff;
    background-color: #e46a76;
}
.badge-success {
    color: #fff;
    background-color: #00c292;
}
.badge-success:hover {
    color: #fff;
    background-color: #009c75;
}
.badge-info:hover{
    background-color: #038fcd;
}
.badge-primary{
    background-color: #6D23FF;
    border-color: #6D23FF;
}
.dropdown-item.active, .dropdown-item:active {
    text-decoration: none;
    color: #fff;
    background-color: #6D23FF;
}
.user-table thead th:nth-child(6) {
    width: 200px;
}
.user-table tbody td:nth-child(4) {
    text-transform: capitalize;
}
span.avtar_text {
    background-color: #ffffff;
    align-items: center;
    justify-content: center;
    display: flex;
    text-transform: uppercase;
    margin: 0 auto;
    color: #6D23FF;
    font-size: 18px;
    width: 100%;
    height: auto;
    border-radius: 50em;
}
.table-sm th{
    background: white !important;
}
.user-table {
    white-space: nowrap;
    table-layout: fixed;
}
.dt-button {
    border-radius: 0.25rem;
    background: #6D23FF;
    text-transform: uppercase;
    font-size: 13px;
    color: #fff;
    margin-right: 8px;
    padding: 6px 30px;
    border: 1px solid #20b5bd;
}

/* Order details page */
.nowrap {
    white-space: nowrap;
}
.border-none {
    border: none !important;
}
.container-fluid {
    padding: 0px 25px;
}
.order-details-page header.card-header {
    padding: 7px 10px;
    font-size: 18px;
    border-radius: 0
}
.order-details-page input {
    height: auto !important;
}
.order-details td, .order-details th {
    border: 1px solid #dee2e6;
    font-size: 13px !important;
}
.general-comment-card .card-body .card-header{
    font-size:12px !important;
}
.general-table td, .general-table th {
    border: none ;
}
.general-card-body {
    padding: 0 !important;
    background: #f8f9fa;
}

.order-history-table tbody tr:nth-of-type(odd) {
    background: #f8f9fa;
}
.form-control:focus {
    /* border: none; */
    border-color:#6D23FF !important;

    /* box-shadow: 0px 0px 4px 0px #0662bc !important; */
    /* -webkit-box-shadow: 0px 0px 4px 0px #0662bc !important; */
    box-shadow: none !important;
}
.order-card {
    padding-right:5px;
    padding-left: 5px;
}

/* order-details table css*/
.order-details .heading {
    font-weight: bold;
    width: 150px;
    max-width: 150px;
}
.order-details td {
    padding: 11px 6px !important;
}
.order-details .td-btn {
    width: 105px;
    background-color: #6D23FF;
    color:white;
}
.theme-bg-color {
    background-color: #6D23FF !important;
}
.general-cmmt-btn {
    background: #6D23FF;color: #fff; padding: 1px 10px; border-radius: 10px;
}
.create-label {
    background-color: #080808;
    color: white;
    padding: .25rem .5rem;
    font-size: 10px;
}
.create-label:hover {
    color:white !important;
    background-color: #009c75;
    border-color: #008f6c
}
/* Error Message CSS */
.error-message{
    padding: 0 !important;
}
.custom-height {
    margin-bottom: 0;
    height: 64px;
}

/* custom button disable */
.custom-btn {
    background-color: #6D23FF;
    border-color: #6D23FF;
}
.custom-btn:hover {
    color: #fff !important;
    background-color: #2b2b2b !important;
    border-color: #2b2b2b;
}
.custom-btn.disabled, .custom-btn:disabled {
    color: #212529;
    background-color: #6D23FF;
    border-color: #6D23FF;
}

/* tagging  */
.public-DraftEditorPlaceholder-root {
    position: absolute !important;
}
.draftJsMentionPlugin__mention__29BEd, .draftJsMentionPlugin__mention__29BEd:visited {
    color: #ffffff !important;
    cursor: pointer;
    display: inline-block;
    background: #6D23FF !important;
    padding: 1px 10px !important;
    border-radius: 10px !important;
    text-decoration: none;
    margin-bottom: 10px !important;
}

.draftJsMentionPlugin__mentionSuggestions__2DWjA {
    background: #6D23FF !important;
    /* color: #ffffff !important; */
}
.btn-primary:hover {
    background-color: #2b2b2b !important;
    border-color: #2b2b2b !important;
}
input[type=checkbox]{
    -ms-transform: scale(1.1); /* IE */
    -moz-transform: scale(1.1); /* FF */
    -webkit-transform: scale(1.1); /* Safari and Chrome */
    -o-transform: scale(1.1); /* Opera */
    transform: scale(1.1);
    padding: 10px;
}
.select-box-div{
    display: inline-block;
}
.select-box-div .css-11unzgr > div {
    display: flex !important;
    align-items: center;
    padding: 10px 8px!important;
    color: #505356 !important;
    font-size: 15px !important;
    background-color: transparent;
    font-weight: normal !important;
}
.select-box-div .css-11unzgr {
    padding: 0px 0 10px;
    box-shadow: 0px 0px 15px -2px #000;
}
.select-box-div .css-11unzgr > div > input {
    height: auto !important;
    margin-right: 10px !important;
}
.css-1r4vtzz  {
    background-color: #20b5bd !important;
    border-radius: 0.25rem !important;
    height: 32px !important;
}
.css-1v99tuv {
    color: #ffffff !important;
}
.css-48ayfv  {
    background-color: #20b5bd !important;
    border-radius: 0.25rem !important;
    height: 32px !important;
}
.css-1pcexqc-container{
    width: 250px;
    overflow-x: hidden;
}
/* .css-1jllj6i-control {
    display: none !important;
} */
.css-1vr111p-option{
    border-bottom: 1px solid #e7e7e7 ;
}
.css-1qprcsu-option{
    border-bottom: 1px solid #e7e7e7 ;
}
.css-1r4vtzz:hover {
    background-color: #2b2b2b !important;
    border-color: #2b2b2b;
}
.select-box-div .css-1gpjby2 {
    color: white;
}
.product-input {
    width: 100%;
    border: 0 !important;
    padding: 0 !important;
}
.scrollable {
    overflow: scroll;
}
.add-product-body .inner-td{
    padding: 0 !important;
}
.add-product-body .table-bordered th, .add-product-body .table-bordered td{
    padding: 7px 10px;
    border: 1px solid #dee2e6;
}
.add-product-body .product-input{
    height: auto !important;
}
td.bg-td-product {
    background-color: #8b8b8b !important;
}
td.bg-td-product input{
    background-color: transparent !important;
}
.bg-td-product .text-white input {
    color: #ffffff !important;
}
.add-product-body .table-bordered td .form-select select {
    width: 100%;
    height: 34px;
    margin: 0;
    border: 1px solid #13b1ba;
    color: #fff;
    font-size: 14px;
    background: #6D23FF;
    background-size: 20px;
    background-position: right 10px center;
    font-family: 'Arial';
    padding-left: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}
.add-product-body .table-bordered td .form-select select:focus{
    border: none !important;
    outline: none !important;
    outline-offset: 0 !important;
}
.form-control[readonly] {
    background-color: #ffffff;
    opacity: 1;
}
.product-input-select div {
    padding: 0;
    border: none;
    min-height: auto !important;
}
.product-input-select:focus{
    border: none;
}
.css-1pahdxg-control:focus, .css-1pahdxg-control:hover{
    border: none !important;
}
.product-input-select .css-1pahdxg-control{
    box-shadow: none !important;
    border: none !important;
}
.css-1uccc91-singleValue {
    position: relative !important;
    transform: inherit !important;
    top: auto !important;
    color: #212529 !important;
}
.css-1fhf3k1-control{
    background-color: #ffffff !important;
}
.nowrap{
    white-space: nowrap;
}
.selected_platform_td {
    font-weight: bold;
    color: #6D23FF;
}
.icheck-list {
    list-style: none;
    padding-left: 0;
}
.css-1v99tuv{
    visibility: hidden ;
    position: relative ;
    width: 32px;
}
.css-1v99tuv:after {
    visibility: visible;
    position: absolute;
    top: 0;
    left: 0;
    content: "Hide";
    width: 32px;
}
.btn-primary.disabled, .btn-primary:disabled{
    color: #fff;
    background-color: #03a9f3 !important;
    border-color: #03a9f3 !important;
}
.btn-success{
    color: #fff;
    background-color: #00c292 !important;
    border-color: #00c292 !important;
}
.btn-success:hover {
    background-color: #009c75 !important;
    border-color: #009c75 !important;
}

/* dashboard css */
.dashboard-card .card-title {
    position: relative;
    font-weight: 500;
    text-align: left;
}
.text-center {
    text-align: center !important;
}
.graph-data {
    list-style: none;
    padding: 0;
    margin: 0;
}
.graph-data li {
    list-style: none;
    display: inline-block;
    color: #fff;
    padding: 5px 15px;
    width: auto;
    background: #2b2b2b !important;
    text-align: center;
    font-size: 16px;
    min-width: 120px;
    margin-right: 5px;
}
.graph-data li:last-child{
    margin: 0
}
.graph-data li span {
    display: block;
    font-size: 14px;
}

.new-form-controls {
    display: inline-block;
    float: right;
    width: auto;
    margin-left: 7px;
}
.ticket-card .card-body{
    padding: 10px;
}
.ticket-card .card-body p.text-muted {
    color: #6c757d !important;
    font-weight: 600;
    font-size: 16px;
    margin: 0;
}
.ticket-card {
    box-shadow: 0px 0px 10px -4px #989898;
    border: none;
}

.sales_by_pl {
    padding: 5px 0 5px 0;
}
.progress-bars-groups img {
    width: 20px;
    margin-right: 4px;
}
img {
    vertical-align: middle;
    border-style: none;
}
.pull-right {
    float: right;
}
.platform-title {
    font-size: 18px !important;
    margin: 0;
}
.sales_by_pl .progress {
    height: 6px !important;
}
.sales_by_pl h5 {
    font-size: 13px;
    font-weight: 500;
    color: #585858;
}
.amazon-progress-bar {
    background-color: rgb(244,98,35,0.6) !important;
    border-radius: 10px;
}

/* --------end----- */

.css-yk16xz-control {
    border: 1px solid #d8dbe0;
}
.css-1wa3eu0-placeholder {
    color: #768192;
}
.form-control{
    color: #212529;
}
.cursor-pointer{
    cursor: pointer;
}
.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
}
.upload-btn-wrapper .btn {
    border: none;
    color:#6D23FF;
    background-color: #eaeaea;
    padding: 8px 20px;
    border-radius: .25rem;
    font-size: 16px;
    width: 100%;
    font-weight: bold;
}
.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    height: 44px !important;
}
.align-center{
    align-items: center;
}
.dataTables_processing {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200px;
    margin-left: -100px;
    margin-top: -26px;
    text-align: center;
    padding: 1em 0;
}
.box-title {
    background: #2a2a2a;
    margin: 0 1px;
    padding: 8px 0;
    color: #fff;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
.export-download{
    background-color: #ffffff;
    height: 35px;
    width: 35px;
    display: inline-flex !important; 
    align-items: center;
    justify-content: center;
}
.toggle {
    position: relative;
    overflow: hidden;
}
.toggle {
    height: 0 !important;
    width: 0 !important;
}
.toggle.ios, .toggle-on.ios, .toggle-off.ios {
    border-radius: 20px;
}
.toggle.btn {
    min-width: 35px !important;
    min-height: 34px;
}
.toggle.btn-sm {
    min-width: 50px;
    min-height: 30px;
}
.toggle-group {
    position: absolute;
    width: 200%;
    top: 0;
    bottom: 0;
    left: 0;
    transition: left .35s;
    -webkit-transition: left .35s;
    -moz-user-select: none;
    -webkit-user-select: none;
}
.toggle.off .toggle-group {
    left: -100%;
}
.toggle-on {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 50%;
    margin: 0;
    border: 0;
    border-radius: 0;
}
.toggle-off, .toggle-on {
    text-align: center;
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.toggle-off {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    right: 0;
    margin: 0;
    border: 0;
    border-radius: 0;
}
.toggle-handle {
    position: relative;
    margin: 0 auto;
    padding-top: 0;
    padding-bottom: 0;
    height: 100%;
    width: 0;
    border-width: 0 1px;
}
.toggle.ios .toggle-handle {
    border-radius: 20px;
}
.toggle.ios .toggle-handle {
    width: 68px !important;
}
.btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.76563rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}
.orders-select{
    align-items: center;
    display: inline-flex;
    justify-content: flex-end;
}
.orders-select .css-2b097c-container{
    width: 150px;
}
.orders-select .css-2b097c-container .css-yk16xz-control {
    min-height: 35px !important;
    margin: 0;
    height: 35px;
}

.c-header .c-header-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='white' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
}
.c-header .c-header-toggler-icon:hover {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(255 255 255 / 62%)' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");

}    
.css-151xaom-placeholder{
    visibility: hidden ;
    position: relative ;
    width: 100px;
}
.css-151xaom-placeholder:after {
    visibility: visible;
    position: absolute;
    top: 0;
    left: 0;
    content: "Search Fields";
    width: 100px;
}

/* Order Details page Table CSS */
.ReactTable .rt-tbody .rt-tr-group {
    border-bottom: none !important;
}
.ReactTable .rt-tbody .rt-td {
    border-left-width: 0;
    border-bottom-width: 0;
}
.ReactTable .rt-thead.-filters{
    border-bottom: none !important;
}
.rt-th.rt-resizable-header {
    background-color: #2b2b2b;
    color: #fff;
    padding: 6px !important;
    box-shadow: none !important;
}
.ReactTable .rt-thead.-header {
    box-shadow: none;
}
.ReactTable .rt-thead.-filters .rt-th {
    border-right: 1px solid #dee2e6 !important;
    border-bottom-width: 0;
    border-left-width: 0;
}

.rt-draggable-container div.draggable-header.enable-drag {
    padding: 0;
    border: none;
}

.rt-tr.-padRow.-odd {
    background-color: #f8f9fa;
}
.rt-th.rt-resizable-header.-sort-desc.-cursor-pointer:before {
    content: "\2191";
    right: 1em;
    position: absolute;
}
.rt-th.rt-resizable-header.-sort-desc.-cursor-pointer:before {
    content: "\2191";
    right: 1em;
    position: absolute;
    font-size: 18px;

}
.rt-th.rt-resizable-header.-sort-asc.-cursor-pointer:after {
    content: "\2191";
    right: 1em;
    position: absolute;
    z-index: 9999999;
    top: 7px;
    transform: rotate(180deg);
    font-size: 18px;
}
.rt-tr-group:hover {
    background-color: #f8f9fa;
}
.new-check .toggle input#filter_late_orders {
    display: none;
}
.rt-th, rt-td {
    border: 1px solid #dee2e6;
}

/*  */
/* New */
.c-body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    /* color: white; */
    background-color: #f2f2f4;
    box-shadow: 0.15rem 0 1.75rem 0 rgba(31, 45, 65, 0.15);
}
.c-sidebar .c-sidebar-minimizer {
    background-color: rgb(255 255 255);
}
.c-sidebar.c-sidebar-minimized .c-sidebar-nav-item:hover>.c-sidebar-nav-dropdown-toggle, .c-sidebar.c-sidebar-minimized .c-sidebar-nav-item:hover>.c-sidebar-nav-link {
    background: #f2f2f4;
}
.btn-info {
    color: #fff;
    background-color: #6D23FF;
    border-color: #6D23FF;
}
.btn-info:hover {
    color: #fff;
    background-color: #6D23FFab;
    border-color: #6D23FF;
}

.btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #6D23FF;
    border-color: #6D23FF;
    box-shadow: 0 0 0 0.2rem rgba(82, 168, 255, 0.5);
}
.btn-info:not(:disabled):not(.disabled):active, .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #6D23FF;
    border-color: #6D23FF;
}
.css-1pahdxg-control {
    box-shadow: 0 0 0 1px #6D23FF !important; 
}
/* Error message  height*/
.textarea-height {
    height: 198px;
    /* margin: 0; */
}
.form-height {
    /* margin: 0; */
    height: 90px;
}
/*  */
/* Crad Design */
.modal-header{
    background-color: #6D23FF !important;
    color: #ffffff !important;
}
.modal-header .close {
    color: #ffffff !important;
}

/* create Orgn CSS */
.profile-div {
    width: 180px;
    height: 180px;
    border-radius: 3px;
    background-color: #f3f3fa;
    margin-right: 30px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.post_cancel {
    position: absolute;
    top: -42px;
    right: -33px;
}
.orgn-color-box{
    width: 29px;
    height: 29px;
    border-radius: 3px;
    position: absolute;
    right: 50px;
    top: 5px;
    z-index: 1
}
.css-2b097c-container .css-2613qy-menu {
    z-index: 2;
}
/* Hide Scrollbar in project */
::-webkit-scrollbar {
    width: 0px;
    border-radius: 20px;
    
    }
    
    /* Track */
    ::-webkit-scrollbar-track {
    background: #f3f3fa; 
    border-radius: 10px;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
    background: #f3f3fa; 
    }
    
    ::-moz-scrollbar {
    width: 0px;
    border-radius: 20px;
    
    }
    
    /*/ Track /*/
    ::-moz-scrollbar-track {
        background: #f3f3fa; 
        border-radius: 10px;
    }
    
    /*/ Handle /*/
    ::-moz-scrollbar-thumb {
        background: #f3f3fa; 
    }
/* -----------End---- */
/* Create User -------------------------*/
.flag-select__option__icon {
    margin-bottom: 11px !important;
}

.sticky_button {
    position: sticky;
    /* background-color: #fff; */
    text-align: right;
    top: 56px;
    padding: 20px 0 !important;
}